import React, { Component } from 'react'
import Camera from '../../components/Camera/Camera';

export default class CameraContainer extends Component {
  render() {
    return (
      <div>
        <Camera theme={this.props.theme}/>
      </div>
    )
  }
}
