import React, { Component } from 'react'
import Home from '../../components/Home/Home';

export default class HomeContainer extends Component {
  render() {
    
    return (
      <div >
        <Home theme={this.props.theme}/>
      </div>
        
      
    )
  }
}
